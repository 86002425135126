
@import "../Mixins";

.orte {

  .content {
    display: flex;
    flex-wrap: wrap;
    padding-left: 32px;
    padding-right: 24px;
    padding-top: 16px;
  }

  .item {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    position: relative;
    min-height: 200px;
    max-height: 350px;
    padding: 0; margin: 0;
    margin-bottom: 16px;

    @media screen and (min-width: 700px) {
      max-width: calc(50% - 8px);
      margin-right: 8px;
    }
  }

  .content :nth-child(even) {
    @media screen and (min-width: 700px) {
      max-width: calc(50% - 8px);
    }

  }

}