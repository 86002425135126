
.NewsDate {
    color: #DD2540;
    font-size: 12px;
    font-weight: 200;
}

.NewsTitle {
    color: #5a5a5a;
    font-size: 20px;
    font-weight: 500;
}

.NewsKeyVisual {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    padding-bottom: 24px;
    margin-top: 16px;
}

.NewsContent {
    font-size: 15px;
    font-weight: 200;
    color: #5a5a5a;
    padding-bottom: 16px;
}

.NewsGalleryContainer {

    display: flex;
    flex-wrap: wrap;
}

.NewsGalleryItem {
    flex-grow: 1;
    width: 50%;
    max-height: 300px;
}

.NewsGalleryContainer :nth-child(odd) {
    padding-right: 8px;
}

.NewsGalleryContainer :nth-child(even) {
    padding-left: 8px;
}

.NewsGalleryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


