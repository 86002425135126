@import url(https://fonts.googleapis.com/css?family=Exo:300,400,500);
@import url(https://fonts.googleapis.com/css?family=Waiting+for+the+Sunrise);

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Exo', sans-serif;
    color: #5A5A5A;

}

main {
    display: inline !important;
}

a {
  color: #E7324F;
}

.noMargin {
    margin: 0;
    padding: 0;
    width: 100%
}

.clipping {
    overflow: hidden;
}

.hs > li,
.item {
    scroll-snap-align: center;
    padding: calc(20px / 2 * 1.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul {
    list-style: none;
    padding: 0;
}

.hs {
    display: grid;
    grid-gap: calc(16px);
    grid-template-columns: repeat(10, 200px);
    grid-template-rows: minmax(300px, 1fr);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(.75 * 20px);
    margin-bottom: calc(-.25 * 20px);
}

.App {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

.openedNav {
    transform: translateX(-270px);
    transition: transform .5s;
}

.closedNav {
    transform: translateX(0);
    transition: transform .5s;
}

.darkenedBottom {
    background-image: linear-gradient(transparent, transparent, #000000FF);
}

.clickable {
    cursor: pointer;
}

.AspectFittedImage {
    zoom: 2;
    display: block;
    margin: auto;
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
}

.custom-select {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
}

.image-gallery-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.image-gallery-item {
    height: 140px;
    width: calc(50% - 5px);
    margin-bottom: 10px;
}

.image-gallery-container :nth-child(odd) {
    margin-right: 5px;
}

.image-gallery-container :nth-child(even) {
    margin-left: 5px;
}

.image-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shadowed {
    box-shadow: 0 0 15px #00000032;
}

@media screen and (min-width: 700px) {

    .App {
        box-shadow: 2px 0px 15px #00000032;
        max-width: 1200px;
    }

    .startseite-flex-container {
        flex: 1 1;
        display: flex;
    }

    .pfarrnachrichten-module {
        margin-top: 20px;
    }

    .startseite-flex-container div {
        flex-shrink: 1;
        flex-grow: 1;
        width: 100%;
    }

    .aktuelles-container {
        display: flex;
        flex-wrap: wrap;
    }

    .aktuelles-item {
        flex-shrink: 1;
        flex-grow: 1;
        width: 49%;
        margin: 4px;
        height: 300px;
    }

}



.v-wrap {
    height: 100%;
    white-space: nowrap;
    text-align: center;
}

.v-wrap-left {
    height: 100%;
    white-space: nowrap;
    text-align: left;
}

.v-wrap:before, .v-wrap-left:before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    margin-right: -.25em;
    height: 100%;
}

.v-box {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
}




.image-gallery-bullets {
    bottom: calc(100% - 210px);
}

.image-gallery-content {
    z-index: 0;
}

.image-gallery-bullet {
    padding: 3px !important;
}

@media screen and (min-width: 700px) {
    .image-gallery-bullets {
        bottom: calc(100% - 410px);
    }
}

.headerBar{filter:drop-shadow(0px 0px 8px rgba(0,0,0,0.2));position:fixed;left:0;top:-0;z-index:100;line-height:1.5rem}.headerBar .content{background-color:white;-webkit-clip-path:polygon(0 0, 100% 0, 120% 80%, 0 100%);clip-path:polygon(0 0, 100% 0, 120% 80%, 0 100%);width:100%;height:90px;margin-bottom:-16px;align-items:center;display:grid;grid-template-columns:100px auto 100px}.headerBar .content img:nth-child(1){max-width:60px;height:auto;align-self:center;margin-left:16px;margin-top:4px;cursor:pointer}.headerBar .content span{align-self:center;text-align:center;color:#E7324F;font-weight:500;font-size:20px}.headerBar .content img:nth-child(3){margin-left:auto;margin-top:-10px;margin-right:30px;cursor:pointer;width:30px}


.NewsDate {
    color: #DD2540;
    font-size: 12px;
    font-weight: 200;
}

.NewsTitle {
    color: #5a5a5a;
    font-size: 20px;
    font-weight: 500;
}

.NewsKeyVisual {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    padding-bottom: 24px;
    margin-top: 16px;
}

.NewsContent {
    font-size: 15px;
    font-weight: 200;
    color: #5a5a5a;
    padding-bottom: 16px;
}

.NewsGalleryContainer {

    display: flex;
    flex-wrap: wrap;
}

.NewsGalleryItem {
    flex-grow: 1;
    width: 50%;
    max-height: 300px;
}

.NewsGalleryContainer :nth-child(odd) {
    padding-right: 8px;
}

.NewsGalleryContainer :nth-child(even) {
    padding-left: 8px;
}

.NewsGalleryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.orte .content{display:flex;flex-wrap:wrap;padding-left:32px;padding-right:24px;padding-top:16px}.orte .item{flex-grow:1;flex-shrink:1;width:100%;position:relative;min-height:200px;max-height:350px;padding:0;margin:0;margin-bottom:16px}@media screen and (min-width: 700px){.orte .item{max-width:calc(50% - 8px);margin-right:8px}}@media screen and (min-width: 700px){.orte .content :nth-child(even){max-width:calc(50% - 8px)}}

.mediathek .content-container{padding:32px}.mediathek .content{display:flex;flex-wrap:wrap}.mediathek .item{flex-grow:1;flex-shrink:1;width:100%;position:relative;min-height:200px;max-height:350px;padding:0;margin:0;margin-bottom:16px}@media screen and (min-width: 700px){.mediathek .item{max-width:calc(50% - 8px);margin-right:8px}}@media screen and (min-width: 700px){.mediathek .content :nth-child(even){max-width:calc(50% - 8px)}}.mediathek .media-overlay{position:absolute;top:32px;pointer-events:none}.mediathek h1{font-family:'Exo', sans-serif;font-size:20px;font-weight:500;color:#5A5A5A}.mediathek p{font-family:'Exo', sans-serif;font-size:15px;font-weight:300;color:#5A5A5A}.mediathek .detail-content :nth-child(1){margin-bottom:8pt}

.ansprechpartner .content-container{padding:32px}.ansprechpartner .content{display:flex;flex-wrap:wrap;line-height:1.5rem}.ansprechpartner .item-container{width:100%;position:relative}.ansprechpartner .button-container :after{position:absolute;right:16px}.ansprechpartner .item-button{font-family:'Exo', sans-serif;font-size:15px;font-weight:300;color:#5A5A5A;display:flex;flex:1 1;flex-direction:row;justify-content:space-between;padding:16px;margin-bottom:12px;box-shadow:0 0 8px #00000016;width:100%;font-size:20px;cursor:pointer}.ansprechpartner h1{margin-top:16px;padding-bottom:8px;font-family:'Exo', sans-serif;font-size:20px;font-weight:500;color:#5A5A5A;font-weight:400;width:100%}.ansprechpartner p{padding-top:16px}

