
@import url('https://fonts.googleapis.com/css?family=Exo:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Waiting+for+the+Sunrise');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Exo', sans-serif;
    color: #5A5A5A;

}

main {
    display: inline !important;
}

a {
  color: #E7324F;
}

.noMargin {
    margin: 0;
    padding: 0;
    width: 100%
}

.clipping {
    overflow: hidden;
}

.hs > li,
.item {
    scroll-snap-align: center;
    padding: calc(20px / 2 * 1.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

ul {
    list-style: none;
    padding: 0;
}

.hs {
    display: grid;
    grid-gap: calc(16px);
    grid-template-columns: repeat(10, 200px);
    grid-template-rows: minmax(300px, 1fr);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(.75 * 20px);
    margin-bottom: calc(-.25 * 20px);
}

.App {
    min-height: 100vh;
    overflow: hidden;
    position: relative;
}

.openedNav {
    transform: translateX(-270px);
    transition: transform .5s;
}

.closedNav {
    transform: translateX(0);
    transition: transform .5s;
}

.darkenedBottom {
    background-image: linear-gradient(transparent, transparent, #000000FF);
}

.clickable {
    cursor: pointer;
}

.AspectFittedImage {
    zoom: 2;
    display: block;
    margin: auto;
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
}

.custom-select {
    position: absolute;
    width: 50px;
    height: 50px;
    background: white;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
}

.image-gallery-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.image-gallery-item {
    height: 140px;
    width: calc(50% - 5px);
    margin-bottom: 10px;
}

.image-gallery-container :nth-child(odd) {
    margin-right: 5px;
}

.image-gallery-container :nth-child(even) {
    margin-left: 5px;
}

.image-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.shadowed {
    box-shadow: 0 0 15px #00000032;
}

@media screen and (min-width: 700px) {

    .App {
        box-shadow: 2px 0px 15px #00000032;
        max-width: 1200px;
    }

    .startseite-flex-container {
        flex: 1 1 0;
        display: flex;
    }

    .pfarrnachrichten-module {
        margin-top: 20px;
    }

    .startseite-flex-container div {
        flex-shrink: 1;
        flex-grow: 1;
        width: 100%;
    }

    .aktuelles-container {
        display: flex;
        flex-wrap: wrap;
    }

    .aktuelles-item {
        flex-shrink: 1;
        flex-grow: 1;
        width: 49%;
        margin: 4px;
        height: 300px;
    }

}
