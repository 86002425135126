

.v-wrap {
    height: 100%;
    white-space: nowrap;
    text-align: center;
}

.v-wrap-left {
    height: 100%;
    white-space: nowrap;
    text-align: left;
}

.v-wrap:before, .v-wrap-left:before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    margin-right: -.25em;
    height: 100%;
}

.v-box {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
}


