
.image-gallery-bullets {
    bottom: calc(100% - 210px);
}

.image-gallery-content {
    z-index: 0;
}

.image-gallery-bullet {
    padding: 3px !important;
}

@media screen and (min-width: 700px) {
    .image-gallery-bullets {
        bottom: calc(100% - 410px);
    }
}
