
@import "../Mixins";

.mediathek {

  .content-container {
    padding: 32px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
  }

  .item {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
    position: relative;
    min-height: 200px;
    max-height: 350px;
    padding: 0; margin: 0;
    margin-bottom: 16px;

    @media screen and (min-width: 700px) {
      max-width: calc(50% - 8px);
      margin-right: 8px;
    }
  }

  .content :nth-child(even) {
    @media screen and (min-width: 700px) {
      max-width: calc(50% - 8px);
    }

  }

  .media-overlay {
    position: absolute;
    top: 32px;
    pointer-events: none;
  }

  h1 {
    @include Title;
  }

  p {
    @include Body;
  }

  .detail-content :nth-child(1) {
    margin-bottom: 8pt;
  }
}