
@import "../Mixins";

.ansprechpartner {

  .content-container {
    padding: 32px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.5rem;
  }

  .item-container {
    width: 100%;
    position: relative;
  }

  .button-container :after {
    position: absolute;
    right: 16px;
  }

  .item-button{
    @include Body;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 12px;
    box-shadow: 0 0 8px #00000016;
    width: 100%;
    font-size: 20px;
    cursor: pointer;
  }

  h1 {
    margin-top: 16px;
    padding-bottom: 8px;
    @include Title;
    font-weight: 400;
    width: 100%;
  }

  p {
    padding-top: 16px;
  }

}
