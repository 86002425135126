
@mixin Title {
  font-family: 'Exo', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #5A5A5A;
}

@mixin Body {
  font-family: 'Exo', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #5A5A5A;
}
