

.headerBar {

  filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.2));
  position: fixed;
  left: 0;
  top: -0;
  z-index: 100;
  line-height: 1.5rem;

  .content {
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 120% 80%, 0 100%);
    width: 100%;
    height: 90px;
    margin-bottom: -16px;
    align-items: center;
    display: grid;
    grid-template-columns: 100px auto 100px;

    & img:nth-child(1) {
      max-width: 60px;
      height: auto;
      align-self: center;
      margin-left: 16px;
      margin-top: 4px;
      cursor: pointer;
    }

    & span {
      align-self: center;
      text-align: center;
      color: #E7324F;
      font-weight: 500;
      font-size: 20px;
    }

    & img:nth-child(3) {
      margin-left: auto;
      margin-top: -10px;
      margin-right: 30px;
      cursor: pointer;
      width: 30px;
    }

  }

}
